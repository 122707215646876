import Vue from "vue";
import VueRouter from "vue-router";
import { state } from "@/store/modules/subscription/state";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("../components/layout/Base.vue"),
    children: [
      {
        path: "",
        alias: "Home",
        component: () => import("../pages/Home.vue"),
      },
      {
        path: "documentos",
        name: "Documents",
        component: () => import("../pages/Documents.vue"),
        meta: {
          breadcrumb: ["Documentos"],
        },
      },
      {
        path: "candidatos",
        name: "Candidates",
        component: () => import("../pages/Candidates.vue"),
        meta: {
          breadcrumb: ["Candidatos"],
        },
      },
      {
        path: "candidatosDiretorio",
        name: "CandidatesDirectory",
        component: () => import("../pages/CandidatesDirectory.vue"),
        meta: {
          breadcrumb: ["Candidatos"],
        },
      },
    ],
  },
  {
    path: "/duvidas",
    component: () => import("../components/layout/Base.vue"),
    children: [
      {
        path: "",
        name: "Questions",
        component: () => import("../pages/question/Main.vue"),
        meta: {
          breadcrumb: ["Perguntas e respostas"],
        },
      },
      {
        path: "visualizar/:slug",
        name: "QuestionsToView",
        component: () => import("../pages/question/ToView.vue"),
        meta: {
          breadcrumb: ["Perguntas e respostas", "Visualizar"],
        },
      },
    ],
  },
  {
    path: "/inscricoes",
    component: () => import("../components/layout/Base.vue"),
    children: [
      {
        path: "",
        name: "Subscription",
        component: () => import("../pages/subscription/Main.vue"),
        meta: {
          breadcrumb: ["Inscrições"],
        },
      },
      {
        path: "login",
        name: "SubscriptionLogin",
        component: () => import("../pages/subscription/Login.vue"),
        meta: {
          breadcrumb: ["Inscrições", "Login"],
        },
      },
      {
        path: "recuperar",
        name: "SubscriptionRecovery",
        component: () => import("../pages/subscription/Recovery.vue"),
        meta: {
          breadcrumb: ["Inscrições", "Recuperar senha"],
        },
      },
      {
        path: "recuperar/novasenha",
        name: "SubscriptionChangePassword",
        component: () => import("../pages/subscription/ChangePassword.vue"),
        meta: {
          breadcrumb: ["Inscrições", "Recuperar senha", "Alteração de senha"],
        },
      },
      {
        path: "inscricao",
        name: "SubscriptionRegister",
        component: () => import("../pages/subscription/Register.vue"),
        meta: {
          breadcrumb: ["Inscrições", "Inscrição"],
        },
      },
      {
        path: "situacao",
        name: "SubscriptionSituation",
        component: () => import("../pages/subscription/Situation.vue"),
        meta: {
          breadcrumb: ["Inscrições", "Inscrição", "Situação"],
        },
      },
      {
        path: "inscricao/atualizar",
        name: "SubscriptionChange",
        component: () => import("../pages/subscription/Change.vue"),
        meta: {
          breadcrumb: ["Inscrições", "Inscrição", "Atualizar"],
        },
      },
      {
        path: "inscricao/confirmar",
        name: "SubscriptionConfirmation",
        component: () => import("../pages/subscription/Confirmation.vue"),
        meta: {
          breadcrumb: ["Inscrições", "Inscrição", "Confirmar"],
        },
      },
      {
        path: "inscricao/confirmada",
        name: "SubscriptionSuccessful",
        component: () => import("../pages/subscription/Successful.vue"),
        meta: {
          breadcrumb: ["Inscrições", "Inscrição", "Confirmada com sucesso"],
        },
      },
    ],
  },
  {
    path: "/peticoes",
    component: () => import("../components/layout/Base"),
    children: [
      {
        path: "",
        name: "Petition",
        component: () => import("../pages/petition/Main.vue"),
        meta: {
          breadcrumb: ["Petição"],
        }
      },
      {
        path: "login",
        name: "PetitionLogin",
        component: () => import("../pages/petition/Login.vue"),
        meta: {
          breadcrumb: ["Petição", "Login"]
        }
      },
      {
        path: "situation",
        "name": "PetitionSituation",
        component: () => import("../pages/petition/Situation.vue"),
        meta: {
          breadcrumb: ["Petição", "Situação"]
        }

      },
      {
        path: "registar",
        name: "PetitionRegister",
        component: () => import("../pages/petition/Register"),
        meta: {
          breadcrumb: ["Petição", "Registrar"]
        }
      }
    ]
  }
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name === "SubscriptionChange" || to.name === "SubscriptionSituation") {
    if (state.token === "") {
      next({ name: "SubscriptionLogin" });
    }
  }
  next();
});

export default router;
