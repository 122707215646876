export default {
  regionais: [],
  serverURI: function () {
    const protocol = window.location.protocol;
    const hostname = window.location.hostname;

    if (hostname === "localhost") {
      return window.atob("aHR0cDovL2xvY2FsaG9zdDozMDAwL2FwaQ==");
    }

    return protocol + "//" + hostname + "/api";
  },
  votoURI: function () {
    const protocol = window.location.protocol;
    const hostname = window.location.hostname;

    if (hostname === "localhost") {
      return window.atob("aHR0cDovL2xvY2FsaG9zdDo5MTAxL3ZvdG8y");
    }

    return protocol + "//" + hostname + "/voto2";
  },
};
