export const getters = {
  getInfo(state) {
    return state.info;
  },

  getParamHotsite(state) {
    return state.paramHotsite;
  },

  getParamSubscription(state) {
    return state.paramSubscription;
  },
};
