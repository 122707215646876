import api from "@/api";
import md5 from 'md5'

export default {
  login: (data) => {
    const authentication = {
      email: data.email,
      senha: data.password,
    };

    return api.post("inscricaos/login", authentication);
  },
  get: (token) => {
    return api.post("inscricaos/loadInscricao", { token });
  },
  getCandidate: (token) => {
    return api.post("inscricaos/loadInscrito", { token });
  },
  create: (data) => {
    const inscricao = {
      tipo: data.responsible.type,
      regional: JSON.stringify(data.responsible.typeRegional),
      responsavelNome: data.responsible.fullname,
      responsavelEmail: data.responsible.email,
      responsavelCpf: data.responsible.cpf,
      senha: data.responsible.password,
      responsavelUF: data.responsible.state
    };

    const candidatos = data.candidates;

    return api.post("inscricaos/novo", { inscricao, candidatos });
  },
  update: (token, data) => {
    const inscricao = {
      nome: data.responsible.plateName,
      responsavelNome: data.responsible.fullname,
      responsavelEmail: data.responsible.email,
      foto_chapa: data.responsible.logo,
      cartaPrograma: data.responsible.letter,
    };

    const inscritos = [];

    for (let i = 0; i < data.candidates.length; i++) {
      let prepareFiles = [];

      data.candidates[i].files.forEach((filesData) => {
        prepareFiles.push({ url: filesData.url });
      });

      let candidate = {
        id: data.candidates[i].id,
        cargo_nome: data.candidates[i].role,
        cpf: data.candidates[i].cpf,
        identidade: data.candidates[i].rg,
        nome: data.candidates[i].nickname,
        nome_completo: data.candidates[i].fullname,
        estado_civil: data.candidates[i].maritalStatus,
        email: data.candidates[i].email,
        telefone: data.candidates[i].phone,
        celular: data.candidates[i].cellphone,
        endereco_cep: data.candidates[i].zipcode,
        endereco_logradouro: data.candidates[i].address,
        endereco_cidade: data.candidates[i].city,
        endereco_bairro: data.candidates[i].district,
        endereco_uf: data.candidates[i].state,
        cargo_empresa: data.candidates[i].profession,
        relato_profissional: data.candidates[i].curriculum,
        documentos: JSON.stringify(prepareFiles),
        plano_associado: data.candidates[i].planAssociate,
        inscricaonumero: data.candidates[i].subscriptionNumber,
        situacao_previdenciaria: data.candidates[i].pensionSituation,
        data_inscricao: data.candidates[i].subscriptionDate,
        matricula: data.candidates[i].registration,
        data_nascimento: data.candidates[i].birthdate,
        data_inicio_empresa: data.candidates[i].admissiondate,
      };

      if (data.subscription.confirmar) {
        let confirm = true;

        for (let j = 1; j < data.candidates[i].files.length; j++) {
          let hasUrl = data.candidates[i].files[j].url
          let required = data.candidates[i].files[j].required
          if (!hasUrl && required) {
            confirm = false
          }
        }

        const EmptyOrNull = function (value) {
          if (data.subscription.fields[value].required) {
            if (data.candidates[i][value] === null || data.candidates[i][value] === "") {
              confirm = false;
            }
          }
        };

        EmptyOrNull('fullname');
        EmptyOrNull('rg');
        EmptyOrNull('cpf');
        EmptyOrNull('maritalStatus');
        EmptyOrNull('email');
        EmptyOrNull('phone');
        EmptyOrNull('zipcode');
        EmptyOrNull('address');
        EmptyOrNull('city');
        EmptyOrNull('district');
        EmptyOrNull('state');
        EmptyOrNull('profession');
        EmptyOrNull('curriculum');
        // EmptyOrNull(data.responsible.letter);

        if (confirm) {
          candidate.situacao = true;
          candidate.datahora_confirmou = new Date();
        } else {
          candidate.situacao = false;
        }
      }

      inscritos.push(candidate);
    }

    return api.post("inscricaos/atualizar", {
      token,
      inscricao,
      inscritos,
    });
  },
  updatePassword: (senha, token) => {
    return api.post("inscricaos/alterarSenha", {
      senha,
      token,
    });
  },
  updateConclusion: (token) => {
    return api.post("inscricaos/concluir", {
      token,
    });
  },
  updateConfirmCandidate: (token, cargo) => {
    return api.post("inscricaos/confirmar", {
      cargo,
      token,
    });
  },
  uploadFile: (index, file, arquivo, token) => {
    // eslint-disable-next-line no-undef
    let formData = new FormData();

    let filetype = file.name;
    filetype = filetype.split(".");
    filetype = filetype[filetype.length - 1];

    let prefix = Date.now() + 'x' + Math.random() + md5(Math.random())
    prefix = md5(prefix )
    let filename = prefix + "-" + arquivo + "-" + index + "." + filetype;
    let filenameWithoutExtension = filename.split(".")[0];

    let hostname = window.location.hostname;
    let client = hostname.startsWith("www")
      ? hostname.split(".")[1]
      : hostname.split(".")[0];

    formData.append("file", file, filename);

    let uploadResult = api
      .post(
        `inscricaos/upload-documento?&filepath=${client}&filename=${filenameWithoutExtension}&token=${token}`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      )
      .then((response) => {
        return response.data;
      })
      .catch((reason) => console.log("FAILURE!! ", reason));

    return uploadResult;
  },
  sendRecovery(email) {
    return api.post("inscricaos/emailRecuperacao", email);
  },
  sendEmail(token, sender, candidate) {
    const inscrito = {
      id: candidate.id,
      cargo_nome: candidate.role,
      cpf: candidate.cpf,
      identidade: candidate.rg,
      nome_completo: candidate.fullname,
      estado_civil: candidate.maritalStatus,
      email: candidate.email,
      telefone: candidate.phone,
      endereco_cep: candidate.zipcode,
      endereco_logradouro: candidate.address,
      endereco_cidade: candidate.city,
      endereco_bairro: candidate.district,
      endereco_uf: candidate.state,
      cargo_empresa: candidate.profession,
      relato_profissional: candidate.curriculum,
    };

    return api.post("inscricaos/preparaEnvioEmail", {
      token,
      remetente: sender,
      inscrito,
    });
  },
};
