import services from "@/services";

export const actions = {
  async login({ commit }, payload) {
    const result = await services.Subscription.login(payload)
      .then((response) => {
        commit("DATA_SUBSCRIPTION", { data: response.data });
        commit("DATA_SUBSCRIPTION_TOKEN", { data: response.data });
        return {
          verified: true,
          message: "Login efetuado, sessão iniciada!",
        };
      })
      .catch((reason) => {
        const error = reason.response.data.error;
        return { verified: false, message: error.message };
      });
    return result;
  },
  async loadDataSubscription({ commit }, payload) {
    const result = await services.Subscription.get(payload)
      .then((response) => {
        commit("DATA_SUBSCRIPTION", { data: response.data });
        return {
          verified: true,
        };
      })
      .catch((reason) => {
        const error = reason.response.data.error;
        return { verified: false, message: error.message };
      });
    return result;
  },
  cleanDataSubscription({ commit }) {
    commit("DATA_SUBSCRIPTION_CLEAN");
  },
  logout({ commit }) {
    commit("DATA_SUBSCRIPTION_CLEAN_ALL");
  },
};
