export const getters = {
  getToken(state) {
    return { token: state.token };
  },

  getResponsible(state) {
    return state.responsible;
  },

  getCandidates(state) {
    return state.candidates;
  },

  getSituation(state) {
    return { situation: state.situation, justify: state.situationjustify };
  },
};
