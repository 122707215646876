<template>
  <div id="app">
    <transition name="fade">
      <pre-loader v-if="loading || !isLoaded"></pre-loader>
    </transition>

    <router-view v-if="isLoaded" />
  </div>
</template>

<script>
import Vue from "vue";
import { mapActions, mapState } from "vuex";
import PreLoader from "@/components/Preloader.vue";

export default Vue.extend({
  name: "App",
  metaInfo: { 
    title: `Processo Eleitoral ${new Date().getFullYear()}`, 
  },
  data() {
    return {
      isLoaded: false,
    };
  },
  components: {
    PreLoader,
  },
  computed: {
    ...mapState(["loading"]),
  },
  methods: {
    ...mapActions("eleicaonet", ["eleicaonetInfo"]),
    ...mapActions(["changeLoading"]),
  },
  async created() {
    this.changeLoading(true);
    await this.eleicaonetInfo();
    this.changeLoading(false);
    this.isLoaded = true;
  },
});
</script>
