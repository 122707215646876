export const state = {
  info: {
    dataFimEleicao: "",
    dataInicioEleicao: "",
    alterarSenhaInicio: "",
    alterarSenhaFim: "",
    entidadeEleicao: "",
    jaComecou: false,
  },
  paramSubscription: [],
  paramHotsite: [],
  paramRecadastramento: [],
};
