import api from "../api";

export default {
  loadEleicaonetInfo: () => {
    return api.get("eleicaonet/info");
  },
  loadDocuments: () => {
    return api.get("hs_documentos");
  },
  loadCandidates: () => {
    return api.get("Chapas");
  },
  loadDiretorios: () => {
    return api.get("Diretorios");
  },
};
