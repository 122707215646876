import Vue from "vue";
import { POSITION, TYPE } from "vue-toastification";

const actions = {
  changeLoading({ state, commit }) {
    commit("CHANGE_LOADING", state.loading ? false : true);
  },
  sendNotification({ state }, payload) {
    const toast = createToastComponent(payload);
    if (state.loading === false) {
      const timeout = getTimeoutSendNotification(state, payload)
      const filterType = selectFilterType(payload)
      Vue.$toast(toast, {
        type: filterType,
        position: POSITION.BOTTOM_RIGHT,
        timeout
      });
    }
  },
}

function createToastComponent(payload) {
  return Vue.component("Toast", {
    template: `<p>${payload.message}</p>`
  });
}

function getTimeoutSendNotification(state, payload) {
  let timeout
  let timeoutSendNotification = state.eleicaonet.paramHotsite.timeoutSendNotification
  if (timeoutSendNotification) {
    if (typeof state.eleicaonet.paramHotsite.timeoutSendNotification == 'number') {
      timeout = state.eleicaonet.paramHotsite.timeoutSendNotification
    } else {
      if (timeoutSendNotification[payload.type]) {
        timeout = timeoutSendNotification[payload.type]
      } else if (timeoutSendNotification[payload.message]) {
        timeout = timeoutSendNotification[payload.message]
      } else {
        timeout = payload.timeout ? payload.timeout : 5000
      }
    }
  } else {
    timeout = payload.timeout ? payload.timeout : 5000
  }
  return timeout
}

function selectFilterType(payload) {
  let filterType = TYPE.DEFAULT;

  switch (payload.type) {
    case "success":
      filterType = TYPE.SUCCESS;
      break;
    case "error":
      filterType = TYPE.ERROR;
      break;
    case "warning":
      filterType = TYPE.WARNING;
      break;
    case "info":
      filterType = TYPE.INFO;
      break;
    default:
      filterType = TYPE.DEFAULT;
  }
  return filterType
}

export default actions;
