export const mutations = {
  SET_ELEICAONET_INFO(state, payload) {
    state.info.dataFimEleicao = payload.DataFimEleicao;
    state.info.dataInicioEleicao = payload.DataInicioEleicao;
    state.info.jaComecou = payload.jaComecou;

    const parametros = payload.parametros;

    for (let i = 0; i < parametros.data.length; i++) {
      if (parametros.data[i].nome === "alterar_senha_inicio") {
        state.info.alterarSenhaInicio = parametros.data[i].valor;
      }

      if (parametros.data[i].nome === "alterar_senha_fim") {
        state.info.alterarSenhaFim = parametros.data[i].valor;
      }

      if (parametros.data[i].nome === "entidade_eleicao") {
        state.info.entidadeEleicao = parametros.data[i].valor;
      }

      if (parametros.data[i].nome === "hotsite") {
        const hotsite = parametros.data[i].valor;
        state.paramHotsite = hotsite ? JSON.parse(hotsite) : {};
      }

      if (parametros.data[i].nome === "inscricao_regionais") {
        const regionais = parametros.data[i].valor;
        state.paramSubscription = regionais ? JSON.parse(regionais) : [];
      }

      if (parametros.data[i].nome === "RECADASTRAMENTO_INSCRICAO") {
        const recadastramento = parametros.data[i].valor;
        state.paramRecadastramento = recadastramento
          ? JSON.parse(recadastramento)
          : [];
      }
    }
  },
};
