export const mutations = {
  DATA_SUBSCRIPTION_TOKEN(state, payload) {
    state.token = payload.data.authToken;
  },
  DATA_SUBSCRIPTION(state, payload) {
    state.situation = payload.data.inscricao.situacao;
    state.situationjustify = payload.data.inscricao.justificativasituacao;
    state.responsible.plateName = payload.data.inscricao.nome;
    state.responsible.letter = payload.data.inscricao.cartaPrograma;
    state.responsible.type = JSON.parse(payload.data.inscricao.regional);
    state.responsible.fullname = payload.data.inscricao.responsavelNome;
    state.responsible.email = payload.data.inscricao.responsavelEmail;
    state.responsible.cpf = payload.data.inscricao.responsavelCpf;

    state.responsible.logo = {};
    state.responsible.logo.url = payload.data.inscricao.foto_chapa;

    for (let i = 0; i < payload.data.inscritos.length; i++) {
      let prepareFiles = [];
      let files = JSON.parse(payload.data.inscritos[i].documentos);

      files.forEach((filesData) => {
        prepareFiles.push({ url: filesData.url });
      });

      const candidate = {
        visible: true,
        order: payload.data.inscritos[i].cargo_ordem,
        id: payload.data.inscritos[i].id,
        role: payload.data.inscritos[i].cargo_nome,
        nickname: payload.data.inscritos[i].nome,
        fullname: payload.data.inscritos[i].nome_completo,
        rg: payload.data.inscritos[i].identidade,
        cpf: payload.data.inscritos[i].cpf,
        maritalStatus: payload.data.inscritos[i].estado_civil,
        profession: payload.data.inscritos[i].cargo_empresa,
        email: payload.data.inscritos[i].email,
        phone: payload.data.inscritos[i].telefone,
        cellphone: payload.data.inscritos[i].celular,
        zipcode: payload.data.inscritos[i].endereco_cep,
        address: payload.data.inscritos[i].endereco_logradouro,
        city: payload.data.inscritos[i].endereco_cidade,
        district: payload.data.inscritos[i].endereco_bairro,
        state: payload.data.inscritos[i].endereco_uf,
        curriculum: payload.data.inscritos[i].relato_profissional,
        files: prepareFiles,
        emailSend: payload.data.inscritos[i].email_enviado,
        status: payload.data.inscritos[i].situacao,
        planAssociate: payload.data.inscritos[i].plano_associado,
        subscriptionNumber: payload.data.inscritos[i].inscricaonumero,
        pensionSituation: payload.data.inscritos[i].situacao_previdenciaria,
        subscriptionDate: payload.data.inscritos[i].data_inscricao,
        registration: payload.data.inscritos[i].matricula,
        birthdate: payload.data.inscritos[i].data_nascimento,
        admissiondate: payload.data.inscritos[i].data_inicio_empresa,
      };

      state.candidates.push(candidate);
    }
  },
  DATA_SUBSCRIPTION_CLEAN(state) {
    state.responsible = {
      type: "",
      fullname: "",
      email: "",
      cpf: "",
      plateName: "",
      letter: "",
      logo: {},
    };

    state.candidates = [];
  },
  DATA_SUBSCRIPTION_CLEAN_ALL(state) {
    state.token = "";
    state.situation = "";
    state.situationjustify = "";
    state.responsible = {
      type: "",
      fullname: "",
      email: "",
      cpf: "",
      plateName: "",
      letter: "",
      logo: {},
    };

    state.candidates = [];
  },
};
