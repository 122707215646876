<template>
  <main class="preloader">
    <div class="preloader-content">
      <img class="logo" src="../assets/logo.png" />
      <div class="loader"></div>
    </div>
  </main>
</template>

<script>
import Vue from "vue";
export default Vue.extend({
  name: "PreLoader",
});
</script>
