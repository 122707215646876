import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";

import VueMeta from "vue-meta";
Vue.use(VueMeta);
console.log('version 1.2.3');
import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

const filterBeforeCreate = (toast, toasts) => {
  if (toasts.filter(t => t.type === toast.type).length !== 0) {
    // Returning false discards the toast
    return false;
  }
  // You can modify the toast if you want
  return toast;
}

Vue.use(Toast, {
  transition: "Vue-Toastification__fade",
  maxToasts: 10,
  newestOnTop: false,
  filterBeforeCreate
});

import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import "./assets/sass/application.scss";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
