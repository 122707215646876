export const state = {
  situation: "",
  situationjustify: "",
  token: "",
  responsible: {
    type: "",
    fullname: "",
    email: "",
    cpf: "",
    plateName: "",
    letter: "",
  },
  candidates: [],
};
