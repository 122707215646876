import Vue from "vue";
import Vuex from "vuex";

import state from "@/store/state";
import mutations from "@/store/mutations";
import actions from "@/store/actions";

import { eleicaonet } from "./modules/eleicaonet";
import { subscription } from "./modules/subscription";

Vue.use(Vuex);

export default new Vuex.Store({
  state,
  mutations,
  actions,
  modules: {
    eleicaonet,
    subscription,
  },
});
